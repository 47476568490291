.not-found {
    width: 100%;
    height: 100%;
}

.not-found .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0rem;
}

.not-found .container .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
}

.not-found .container .logo img {
    margin-top: -12rem;
    margin-bottom: -10rem;
}

.not-found .container h4 {
    text-decoration: underline;
}

@media screen and (max-width:768px) {
    .not-found .container .logo img {
        margin-top: -5rem;
        margin-bottom: -10rem;
    }
}

@media screen and (max-width:425px) {
    .not-found .container .logo img {
        margin-top: -0rem;
        margin-bottom: 0rem;
    }
}