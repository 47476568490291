 .clients--container {
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     width: 100%;
     padding-top: 2rem;
     padding-bottom: 2rem;

 }

 .clients--logo-container {
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: flex-start;
     flex-direction: column;
     user-select: none;

 }

 .clients--container h2 {
     font-size: 3rem;
     color: black;
 }

 .clients--logo-container .clients--logo {
     display: flex;
     justify-content: center;
     align-items: center;
     margin: 2rem 1rem;
     padding: 2rem 1rem;
     border-radius: 1.2rem;
     transition: var(--transition);
     border: 1px solid transparent;
 }

 .clients--logo-container .clients--logo:hover {
     background-color: transparent;
     border-color: var(--color-bg1);
 }

 /* RESPONSVIVENESS */

 @media screen and (max-width:425px) {
     .clients--container {
         margin-bottom: 2rem;
         margin-left: 0rem;
         width: 100%;
     }

     .clients--logo-container {
         width: 80%;
         /* background-color: red; */
     }
 }

 @media screen and (max-width:375px) {

     .clients--container {
         margin-bottom: 2rem;
         margin-left: 0rem;
         width: 100%;
     }

     /* .clients--logo-container { */
     /* background-color: red; */
     /* } */
 }