.cta {
    width: 80px;
    height: 80px;
    position: fixed;
    bottom: 1rem;
    right: 1rem;


}

.cta .cta--container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1.2rem;
    transition: var(--transition);
    cursor: pointer;
    border-radius: 0.5rem;
}



.cta .cta--container .bottom img {
    width: 80%;
    transition: var(--transition);
}

.cta .cta--container:hover img {
    width: 100%;
}

.cta .cta--container .top {
    width: 100%;
    height: 100%;
    /* background-color: green; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: absolute;
    top: -1.8rem;

}

.cta .cta--container .bottom {
    width: 100%;
    height: 100%;
    /* background-color: gray; */
    display: flex;
    justify-content: center;
    align-items: center;
}