.navbar {
    width: 100%;
    height: 6rem;
    font-size: 1.2rem;
    position: fixed;
    top: 0;
    left: 0;

    background-color: var(--color-black);
    z-index: 10;
    font-family: 'Montserrat', sans-serif;
}

.navbar--items {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-right: 1.5rem;

}

.navbar--items .logo {
    width: 15%;
    height: 15%;
    padding-left: 5rem;
    padding-top: 0.5rem;
}

.navbar--items .logo img {
    width: 60%;
    cursor: pointer;
}



.navbar--items .navbar--links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    height: 100%;
}

.navbar--items .navbar--links a {
    color: var(--color-white);
}


.navbar--items .navbar--links .navbar--link {
    margin: 0.5rem 1rem;
    font-size: 1rem;
    transition: var(--transition);
    cursor: pointer;
    color: var(--color-black);
}

.navbar--items .navbar--links .navbar--link:hover {
    color: var(--color-bg2);
}

.navbar--icon {
    font-size: 1.5rem;
    transition: var(--transition);
    cursor: pointer;
    display: none;
}

.navbar--icon:hover {

    color: var(--color-bg2);
}

/* RESPONSVIVENESS */
/* @media (max-width:1440px) {} */




@media screen and (max-width:1400px) {
    .navbar--items .navbar--links .navbar--link {

        font-size: 1rem;

    }
}

@media (max-width:1024px) {
    .navbar {
        height: 8.5rem;

    }



    .navbar--items .navbar--links {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 60%;
        height: 100vh;
        position: absolute;
        right: 0px;
        top: 0rem;
        background-color: var(--color-bg2);
        box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.9);
        z-index: -1;
    }

    .navbar--links {
        display: block;
        position: absolute;
        top: 8rem;
        right: 10rem;
        width: 100%;
        height: 25rem;

    }


    .navbar--items .navbar--links .navbar--link {
        margin: 0.5rem 1rem;
        font-size: 1.5rem;
        transition: var(--transition);
    }

    .navbar--link {
        padding: 0.5rem;
    }

    .navbar--items .logo {
        width: 25%;
        height: 25%;
        padding-left: 1rem;
        padding-top: 0.2rem;
        margin-left: -5rem;
    }

    .icon--ham {
        display: block;
        margin: 2.5rem;
        margin-right: -10rem;
    }

    .icon--cross {
        display: none;
        margin: 2.5rem;
        margin-right: -10rem;
    }


}

@media screen and (max-width:768px) {

    .navbar--items .logo img {
        width: 100%;
        margin-left: -3rem;
    }

    .navbar--icon {
        font-size: 2rem;
    }

    .navbar--link {
        font-size: 1.2rem;
    }
}

@media screen and (max-width:540px) {
    .navbar--items .logo img {
        padding-left: 2rem;
        margin-left: 2rem;
    }

    .icon--ham {
        margin: 2.5rem;
        margin-right: -3rem;
    }

    .icon--cross {
        margin: 2.5rem;
        margin-right: -3rem;
    }
}




@media screen and (max-width:425px) {


    .navbar--items .logo {
        width: 70%;
        height: 50%;
        padding-left: 2rem;
        padding-top: 1rem;
    }


    .navbar--items .logo img {
        width: 55%;
        margin-left: 1rem;
    }

    .icon--ham {
        margin: 2.5rem;
        margin-right: -2rem;
    }

    .icon--cross {
        margin: 2.5rem;
        margin-right: -2rem;
    }

    .navbar--items .navbar--links .navbar--link {

        font-size: 1rem;

    }
}

@media screen and (max-width:375px) {

    .icon--ham {
        margin: 2.5rem;
        margin-right: -1rem;
    }

    .icon--cross {
        margin: 2.5rem;
        margin-right: -1rem;
    }
}

@media screen and (min-width:2200px) {
    .navbar {
        height: 8.5rem;
    }

    .navbar--items .logo {
        width: 25%;
        height: 25%;
        padding-left: 5rem;
        padding-top: 0.2rem;
    }

    .navbar--items .navbar--links .navbar--link {

        padding-top: 1.5rem;

    }


}

@media screen and (min-width:1800px) {
    .navbar--items {
        max-width: 1800px;
        margin: auto;
    }

    .navbar--items .logo {
        width: 25%;
        height: 25%;
        padding-left: 5rem;
        padding-top: 0.2rem;
    }



    .navbar--items .logo img {
        width: 40%;
    }

    .navbar--items .navbar--links .navbar--link {

        font-size: 1.5rem;

    }
}