.main {
    margin-top: 0rem;
    width: 100%;
    min-height: 100vh;

}

.main .container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 40% 60%;
    /* gap: 10rem; */
    overflow: hidden;
    background-color: var(--color-bg);
    color: var(--color-black);
    padding-bottom: 5rem;
}

.main .container .left {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 5rem;
    margin-top: -8rem;
    margin-bottom: 5rem;

}

.main .container .left p {
    /* align-items: ; */
    width: 100%;
    margin-bottom: 2rem;
    margin-top: -3rem;
    font-size: 1.3rem;
}



.main .container .left h2 {
    text-align: left;
    width: 100%;
    margin-top: 25rem;
    font-size: 1.5rem;

}

.main .container .left h1 {
    /* background-color: #161a5a; */
    height: 1rem;
    text-align: center;
    color: #000;
    margin-bottom: 2rem;
    margin-top: 0rem;
    font-size: 4rem;
}




.main .container .left .btn {
    color: var(--color-white);
    padding: 1.5rem 2rem;
    background-color: #05a4ec;
    border: 1px solid transparent;
    outline: 0;
    transition: var(--transition);
    cursor: pointer;
    font-size: 1.5rem;
    border-radius: 1rem;

}

.main .container .left .btn:hover {
    background: transparent;
    border-color: var(--color-danger);
    color: #000;
}

.main .container .left .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
}

.main .container .right {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3.5rem;
    /* background-color: #fff; */
}

.main .container .right img {
    margin-right: 10rem;
    width: 65%;
}



.typewrite-content {
    text-align: left;
    margin-bottom: 3rem;
    margin-top: -2rem;
    text-transform: capitalize;
    width: 100%;

}


.typewriter {
    width: 100%;
    font-size: 1.5rem;
    font-weight: bold;
    padding-bottom: 5rem;
}

.intro-play {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 20000;
    user-select: none;
}



.intro-play-btn-img {
    width: 100px;
    height: 100px;
    position: relative;
    transition: var(--transition);
    border-radius: 2rem;

}

.intro-play-btn-img:hover {
    transform: scale(1.2);
}

.intro-play-btn-img img {
    cursor: pointer;
}

.play-btn-animate {
    height: 100px;
    width: 100px;
    background: transparent;
    border-radius: 50%;
    border: 2px solid #05a4ec;
    animation-name: play;
    /* animation: play 1s linear infinite; */
    position: absolute;
    top: 0;
    left: 0;
}

.play-btn-triangle {
    height: 100px;
    width: 100px;
    /* background-color: red; */
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 6rem;
}

.play-icon {
    padding-left: 0.6rem;
    padding-top: .1rem;
    color: #05a4ec;
}



@keyframes play {
    0% {
        border-width: 1px;
    }

    50% {
        border-width: 4px;
    }

    100% {
        border-width: 1px;
    }
}

/* //////////////////////////////////////////////////////// */




/* //////////////////////////////////////////////////////// */

/* RESPONSVIVENESS */



@media screen and (max-width:1024px) {
    .main .container .left h2 {
        margin-top: 20rem;

    }

    .main .container .left p {
        /* ; */
        font-size: 1rem;

    }
}

@media screen and (max-width:858px) {
    .main {
        width: 90%;
        height: 100%;
        margin: auto;
    }



    .main .container .left {
        padding-left: 2rem;
        position: absolute;
        top: 5%;
        left: 5%;
        width: 80%;
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: 1rem;
        margin: auto;
        padding: 3rem;

    }

    .main .container .left p {

        width: 90%;
        font-size: 1.3rem;

    }

    .main .container .left h2 {
        font-size: 2rem;
    }

    .main .container .left .buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4rem;
    }

    .main .container .right {
        position: absolute;
        top: 0%;
        left: 10%;
        z-index: -1;
    }

    .main .container .right img {
        margin-top: 0rem;
        width: 90%;
    }

    .main .container .left .btn {
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }
}


@media screen and (max-width:800px) {
    .main .container {
        padding-bottom: -10rem;
        margin-bottom: -5rem;
    }

    .main .container .left h2 {
        margin-top: -15rem;

    }
}

@media screen and (max-width:600px) {
    .main .container .left h2 {
        margin-top: 2rem;

    }

}

@media screen and (max-width:520px) {
    .main .container .right img {
        margin-right: -30rem;
        margin-top: 18rem;
        width: 140%;
    }



}

@media screen and (max-width:425px) {
    .main {
        margin-top: 8rem;
        width: 100%;
    }

    .main .container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        gap: 0rem;
    }



    .main .container .left p {
        width: 100%;
        font-size: 1rem;

    }

    .main .container .right img {

        width: 50%;
    }

    .main .container .left h2 {
        margin-top: 15rem;

    }

    .main .container .left .buttons {
        flex-direction: column-reverse;
        margin-bottom: 6rem;
        gap: 1rem;
    }
}

@media screen and (max-width:390px) {
    .main .container .left h2 {
        margin-top: 20rem;

    }

    .main {
        margin-bottom: 0rem;
    }
}



@media screen and (max-width:360px) {

    .main .container .left h2 {
        margin-top: 45rem;

    }

}

@media screen and (max-width:260px) {

    .typewiter-heading {
        font-size: 2rem;
        padding-bottom: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;


    }



    .typewriter {
        width: 100%;
        /* background: red; */
        font-size: 20rem;
    }

}


.main .container .right img {
    width: 80%;
    margin-right: 10rem;
    margin-top: 5rem;
}