.servicebar a {
    padding: 1rem;
}

.services-new-details {
    margin-top: 8rem;
}


.configurator-title {
    color: #000;
    margin-top: 15rem;
    /* text-transform: uppercase; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;

    background-color: #e7e7e7;
}

.configurator-title .underline {
    width: 90%;
}

.configurator-title h1 {
    font-size: 4rem;
    margin-bottom: 0;
}

.configurator--service-container {
    color: black;
    background-color: #e7e7e7;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5rem;
    padding: 2rem 8rem;
}

.configurator-container {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 1fr;
    border: 1px solid rgb(119, 108, 108);
    border-radius: 2rem;
    background-color: #e7e7e7;
    transition: var(--transition);
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.configurator-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem;

}

.configurator-video {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5rem;
}

.configurator-video-components {
    padding: 2rem;
}

.configurator-container:hover img {
    transform: scale(1.1);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.8);
}


.configurator-container .configurator-image img {
    width: 100%;
    border-radius: 2rem;
    transition: var(--transition);


}

.configurator-details {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    left: 0;
    opacity: 0;
    transition: var(--transition);
}

.configurator-container:hover .configurator-details {
    opacity: 1;
}

.configurator-details p {
    text-align: center;
    display: none;
}


@media screen and (max-width:1024px) {
    .confi-bar {
        z-index: 0;
    }

    .configurator--service-container {
        grid-template-columns: repeat(2, 1fr);

    }

    .configurator-video {
        flex-direction: column;
    }
}

@media screen and (max-width:768px) {
    .configurator-video {
        flex-direction: column;
    }
}

@media screen and (max-width:425px) {
    .configurator-video p {
        display: none;
    }
}