.aboutus {
    color: var(--color-black);
    margin-top: 8rem;
    user-select: none;
}



.aboutus .container .achievements {

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    /* background-color: #fff; */
}

.achievements--box {
    width: 90%;
}

.aboutus .container .achievements .left {
    width: 100%;
    height: 100%;
    /* background-color: #fff;/ */
}

.aboutus .container .achievements .left img {
    width: 100%;
}

.box--container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
}

.aboutus .container .achievements .right .achievements--box_cards {
    background: var(--color-bg1);
    border-radius: 2rem;
    width: fit-content;
    padding: 2rem 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: var(--transition);
}



.aboutus .container .achievements .right .achievements--box_cards:hover {
    background: var(--color-bg2);
}

.aboutus .container .achievements .right .achievements--box_cards:hover .achievements-icon {
    background: transparent;
    border: 1px solid var(--color-white);
}

.achievements-icon {
    font-size: 2rem;
    background: var(--color-danger);
    border-radius: 1.5rem;
    padding: 1rem;
    border: 1px solid transparent;
    color: #fff;
}

.achievements--box_card h2 {
    color: #fff;
    margin-bottom: 1rem;
}

.achievements--box_card small {
    color: #fff;
}

.aboutus .team {
    margin-top: 5rem;
    padding-bottom: 5rem;
}

.aboutus .team h2 {
    margin-top: 2rem;
    padding-top: 2rem;
}


.about-media-container,
.about-design-container {
    margin: 5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.about-media-container .right img {
    width: 80%;
}

.about-intro {
    margin: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;

}



.team--container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
}

.team--container .team--card {
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background: var(--color-bg2); */
    padding: 2rem;
    transition: var(--transition);


}

.team--container .team--card .image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    transition: var(--transition);
    width: 70%;
}




.team--container .team--card:hover {
    border-color: var(--color-white);
    background: transparent;
}






.team--container .team--card h2 {
    margin-top: 0;
    margin-bottom: 0;
}




@media screen and (max-width:768px) {

    .about-intro {
        flex-direction: column;
        gap: 5rem;
    }

    .right .achievements--box h1 {
        font-size: 1.8rem;
    }

    .aboutus .container .achievements {

        flex-direction: column;
    }

    .about-media-container,
    .about-design-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .about-design-container {
        flex-direction: column-reverse;
    }

    .about-media-container p,
    .about-design-container p {
        font-size: 1.3rem;
    }



    .box--container {
        margin: auto;
        width: 50%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 2rem;
    }

}

@media screen and (max-width:425px) {



    .aboutus .container .achievements img {
        width: 100%;
    }

    .aboutus .container .achievements .right {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .aboutus .container .achievements .right h1 {
        font-size: 2rem;
    }

    .aboutus .container .achievements .right p {
        width: 85%;
        font-size: 1.3rem;
        text-align: center;
    }

    .aboutus .container .achievements {

        flex-direction: column;
        /* background-color: #fff; */
    }



    .box--container {
        display: flex;
        justify-content: center;
        align-items: center1;
        flex-direction: column;
    }

    .team--container {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width:2560px) {
    .about-intro-video {
        width: 1260px;
        height: 720px;
    }
}