.projects {
    margin-top: 10rem;
    margin-bottom: 5rem;
}

.projects .container .projects--links {
    position: fixed;
    display: flex;
    justify-content: left;
    align-items: left;
    flex-direction: column;
    font-size: 1.5rem;
    border-right: 1px solid black;
    height: 80%;
    padding-right: 1rem;
    color: var(--color-black);
    overflow-y: scroll;
}

.filter-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e7e7e7;
    flex-direction: row;
    /* z-index: 100000000000000; */
    position: absolute;
    color: #000;
    width: 100%;
    gap: 0.5rem;
    display: none;
}

.filter-details {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 0.3rem;
    font-size: 1.5rem;
    padding: 0.5rem;
    cursor: pointer;
    transition: var(--transition);
}

.filter-details:hover {
    transform: scale(1.2);
}







.projects .container .projects--links .projects--link {
    padding: 0.5rem;
    margin: 0.5rem;
    cursor: pointer;
    transition: var(--transition);

}

.projects .container .projects--links .projects--link:hover {
    background-color: var(--color-bg2);
    border-radius: 0.5rem;
    color: var(--color-white);
}

.projects--link-active {
    padding: 1rem;
    margin: 1rem;
    background-color: var(--color-bg2);
    border-radius: 0.5rem;
    color: var(--color-white);
}

.projects .container .projects--data {
    display: grid;
    grid-template-columns: 1fr 3.2fr;
    gap: 1rem;
    color: black;
    margin-right: 5rem;

}

.projects .container .projects--data {
    border-radius: 5rem;

}

.projects--data .project--container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    margin-right: 2rem;

}

.projects--data .project--container .projects--card {
    border: 1px solid var(--color-black);
    padding: 0.5rem;
    display: flex;
    gap: 1.5rem;
    height: 250px;

}

.projects--data .project--container .projects--card {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    cursor: pointer;
    transition: var(--transition);
}


.projects--data .project--container .projects--card:hover .projects--card-image img {
    transform: scale(1.05);
    transition: var(--transition);
    border-radius: 2rem;
}

.projects--data .project--container .projects--card:hover {

    cursor: pointer;
    transition: var(--transition);
}

.projects--data .project--container .projects--card:hover {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.8);
    border-radius: 2rem;
}

.projects--card-image {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    object-fit: contain;

}

.projects--card-image img {
    width: 90%;
    aspect-ratio: 16/9;
}


.projects--card-details {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.projects--card-details h2 {
    width: 100%;
    text-align: left;
    margin-bottom: 0rem;
    font-size: 1.2rem;
}

.projects--card-details p {
    width: 100%;
    /* margin-bottom: 0rem;
    font-size: 1.2rem; */
}

.projects--card-details .btn {
    padding: 1rem;
    background: var(--color-danger);
    border: 1px solid transparent;
    outline: 0;
    color: var(--color-white);
    cursor: pointer;
    transition: var(--transition);
}

.projects--card-details .btn:hover {
    background: transparent;
    border-color: var(--color-white);
}

.youtubeVideo .overlay {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.4);
}

.youtubeVideo {
    width: 99vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}



.youtubeVideo iframe {
    z-index: 2000;
}

.youtubeVideo .btn {
    margin-top: 1rem;
    padding: 1rem 2rem;
    z-index: 2000;
    cursor: pointer;
    background: var(--color-danger);
    outline: 0;
    border: 1px solid transparent;
    transition: var(--transition);
    color: var(--color-white);
    font-size: 1rem;
}

.youtubeVideo .btn:hover {
    background: transparent;
    border-color: var(--color-white);
}

.hidden {
    display: none;
}

.imageViewer>.container {
    width: 80%;
    height: 80%;
}




@media screen and (max-width:1440px) {
    .youtubeVideo {
        width: 100%;
        height: 100%;
    }

    .youtubeVideo iframe {
        width: 1000px;
        height: 800px;
    }



    .youtubeVideo .btn {
        margin-top: 0rem;
    }

    .projects .container .projects--links {

        margin-top: 8rem;
        height: 55%;
        padding-right: 1rem;

    }

    .projects .container .projects--links .projects--link {
        padding: 0.5rem;
        margin: 0.5rem;
        font-size: 1.1rem;
    }

    .projects--data .project--container .projects--card-details h2 {
        font-size: 1.5rem;
    }

    .projects--data .project--container .projects--card-details p {
        font-size: 0.8rem;
    }

}


@media screen and (max-width:1024px) {
    .projects--data .project--container .projects--card-details h2 {
        font-size: 1.2rem;
    }

    .projects--data .project--container .projects--card-details p {
        font-size: 0.7rem;
    }

    .projects .container .projects--links .projects--link {
        padding: 0.5rem;
        margin: 0.5rem;
        font-size: 0.8rem;
    }

    .projects--data .project--container .projects--card {
        width: 100%;
        overflow: hidden;
    }

    .youtubeVideo iframe {
        width: 1024px;
        height: 1024px;
    }
}



@media screen and (max-width:834px) {
    .projects .project--container {

        margin-top: 5rem;
    }

    .filter-box {
        display: flex;
    }

    .projects .container .projects--links {
        display: none;
        background-color: #fff;
        height: 100%;
        width: 50%;
        margin-top: 2rem;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);

    }

    .projects--data .project--container .projects--card img {
        /* margin-top: 2rem; */
        min-width: 100%;
        aspect-ratio: 16/9;

    }

    .projects--data .project--container .projects--card-details h2 {
        font-size: 1rem;
    }

    .projects--data .project--container .projects--card-details p {
        font-size: 0.6rem;
    }
}

@media screen and (max-width:768px) {

    .projects--data .project--container {

        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }



    .projects .container .projects--links {

        height: 100%;
        padding-right: 1rem;
    }

    .projects .container .projects--links {

        border-right: 1px solid transparent;
    }

    .projects .container .projects--links .projects--link {

        font-size: .8rem;
        padding-top: 2rem;
        border-color: transparent;
    }

    .projects--data .project--container .projects--card {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

    }

    .projects--card-details p {
        display: none;
    }


    .projects .container .projects--links .projects--link {
        padding: 0.5rem;
        margin: 0.5rem;

    }

    .projects .container .projects--data {
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: 2rem;
    }

    .projects--data .project--container .projects--card img {
        margin-top: 2rem;
        min-width: 100%;
        aspect-ratio: 16/9;

    }
}



@media screen and (max-width:540px) {
    .projects--data .project--container {
        grid-template-columns: repeat(3, 1fr);
        gap: 4rem;

    }

    .projects--data .project--container .projects--card {
        display: grid;
        gap: 1rem;
        color: black;
        margin-right: 5rem;

    }

    .projects--data .project--container .projects--card {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .projects--data .project--container .projects--card img {
        width: 50%;
    }

    .projects--card-details h2 {
        font-size: 1.1rem;
    }

    .projects--card-details p {
        display: none;
    }



    .projects .container .projects--data {
        display: grid;
        grid-template-columns: 0 1fr;
        gap: 2rem;
    }
}

@media screen and (max-width:425px) {


    .projects--data .project--container {
        grid-template-columns: repeat(1, 1fr);
        gap: 3rem;

    }

    .projects--data .project--container .projects--card:hover .projects--card-image img {
        border-radius: 1rem;
    }

    .projects--data .project--container .projects--card img {
        min-width: 100%;
        aspect-ratio: 16/9;
    }



    .projects--data .project--container .projects--card-details h2 {
        font-size: 0.8rem;
    }



}

@media screen and (max-width:375px) {
    .projects--data .project--container .projects--card-details h2 {
        font-size: 0.8rem;
    }


}

@media screen and (min-width:1800px) {
    .youtubeVideo .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: -1;
        background-color: rgba(0, 0, 0, 0.4);
    }

    .projects .container {
        max-width: 1800px;
        margin: auto;
    }

    .projects .container .projects--links {

        margin-top: 5rem;
        height: 55%;
        padding-right: 1rem;

    }

    .youtubeVideo iframe {
        width: 1400px;
        height: 700px;
    }
}