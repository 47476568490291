.services-new-section {
    margin-top: 2rem;
}

.services-new-details {
    padding-top: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.services-new-details h2 {
    /* background-color: #ebe7e74d; */
    font-size: 5rem;
    padding-bottom: 0;

}

.underline {
    background-color: rgb(209, 207, 207);
    width: 10%;
    height: 0.3rem;
}

.servicesbar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    left: 0;
    color: var(--color-black);
    background-color: #161a5a;
    /* z-index: 1000000000; */

}

.servicebar {
    padding: 1rem;
    /* background-color: #8080804d; */

    cursor: pointer;
}

.servicebar a {
    color: #fff;
    transition: var(--transition);
}

.servicebar-list {
    text-align: center;
    padding: 2rem;
    width: 2rem;

}


.servicebar-list:hover {
    color: #857c7c;
}




.services-new--container {
    color: var(--color-black);
    margin-right: 0;
    background-color: #ebe7e74d;
}

.service-new-container {
    width: 40%;
    font-size: 1.3rem;

}

.services-new--container p {
    text-align: center;
    margin-bottom: 0;
}

.services-new--container h3 {
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
}

.services-new {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 6rem;
    background-color: #f9f8f8;
}



.services-image-container {
    margin-top: 6rem;
    width: 50%;
}

.services1-new-container {
    /* background-color: red; */
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5rem;
    padding: 2rem;


}

.services1-new-container img {
    aspect-ratio: 16/9;
    transition: var(--transition);
}

.services1-new-container img:hover {
    transform: translateY(-10px);
    cursor: pointer;

}

.projects--card-new {

    transition: var(--transition);

}



.projects--card-new img {
    width: 80%;
    border-radius: 1.3rem;

}

.seperator {
    height: 0.3rem;
    outline: none;
    border: none;
    background-color: #8080804d;
    border-radius: 50%;
}

.new2 {
    flex-direction: row-reverse;
    background-color: #f1f1f1;
}




/* RESPONSVIVENESS */




@media screen and (max-width:1440px) {
    .service-new-container {
        width: 40%;
        font-size: 1.3rem;
    }

    .servicebar a {
        font-size: 1.2rem;
    }
}

@media screen and (max-width:1200px) {
    .service-new-container {
        width: 40%;
        font-size: 1.1rem;
    }

    .servicebar a {
        font-size: 1.1rem;
    }


}

@media screen and (max-width:1100px) {
    .service-new-container {
        width: 40%;
        font-size: 1rem;
    }

    .servicebar a {
        font-size: 1rem;
        white-space: nowrap;
    }


}

@media screen and (max-width:1024px) {
    .servicebar a {
        text-wrap: wrap;
        /* background-color: red; */

        font-size: 0.6rem;
    }

    .services1-new-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .servicebar {
        text-align: justify;
    }
}




@media screen and (max-width:768px) {
    .services-new {
        position: relative;

    }

    .services-new .services-image-container {
        position: absolute;
        top: 0%;
        left: 50%;
        left: 0;

        opacity: 1;
    }

    .services-new .services-image-container img {
        width: 80%;
    }


    .services-new .service-new-container {
        padding: 2rem;
        z-index: 5;
        width: 70%;
        background-color: rgba(255, 255, 255, 0.7);
        margin-bottom: 1rem;

    }

    .services-new .service-new-container p {
        font: 5rem;
    }


    .services1-new-container {
        margin-top: 5rem;
        grid-template-columns: repeat(1, 1fr);
    }

    /* .service-new-container {
       
        font-size: 1.3rem;
        text-align: left;
    } */

    .services-image-container {
        margin-top: 0rem;
        width: 100%;
    }

}



@media screen and (max-width:425px) {
    .servicebar {
        display: none;
    }


    .services-new .service-new-container p {
        font: 0.5rem;
    }

    .services-new .service-new-container h3 {
        font-size: 1.5;
        text-align: center;
        width: 100%;
    }

    .service-new-container {
        width: 70%;
        font-size: 1.3rem;
    }

    .service-new-container h2 {
        width: 70%;
        font-size: 2rem;
    }

    .service-new-container p {
        text-align: center;
    }


    .services1-new-container {
        flex-direction: column;
        background: #f9f8f8;
        gap: 5rem;

    }

    .services-image-container {
        margin-top: 0rem;
        width: 100%;
    }


    .projects--card-new {
        width: 80%;

    }

    .services-new--container p {
        font-size: .8rem;
    }

    .services-new .services-image-container img {
        width: 100%;
    }

    .services-new-details h2 {
        font-size: 2.5rem;
    }

    .underline {
        width: 50%;
        margin-top: -3rem;
    }


}

@media screen and (max-width:375px) {

    .services-new-section {
        margin-top: 10rem;
    }

    .service-new-container {
        flex-direction: column;
    }

    .services-new--container {
        margin-top: 10rem;
        width: 100%;
    }


}

@media screen and (max-width:260px) {
    .services-new--container {
        margin-top: 10rem;
        width: 90%;
    }
}