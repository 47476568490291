footer {
    width: 100%;
    background-color: #000;
}

.footer {
    width: 100%;

}

.footer .container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    margin-top: 3rem;
    padding-bottom: 8rem;
    background-color: var(--color-black);
    color: #fff;
    width: 100%;
}

.footer .container .title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.footer .container .title h3 {
    margin: 0;
    padding: 0;


}

.footer .container .title img {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #fff; */
    width: 100%;
    margin-bottom: 0;
    /* margin-bottom: -2rem; */
}

.permalinks {
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    margin-top: 1.2rem;

}

.permalinks a {
    color: #fff;
    margin: 1rem 0;
    transition: var(--transition);
}

.permalinks a:hover {
    color: var(--color-bg);
}

.other-links {

    margin-top: 1.2rem;
    display: flex;
    justify-content: top;
    align-items: left;
    flex-direction: column;

}

.other-links .other-link {
    padding: 1rem 0rem;
    text-align: left;


}

.other-links .other-link a {
    transition: var(--transition);
    color: #fff;
}

.other-links .other-link a:hover {
    color: var(--color-bg);
    color: #fff;
}

.contact--perma {
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    color: #fff;
}

.contact--perma h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: left;
}

.footer .container .socials {

    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    color: #fff;
}


.footer .container .socials .socials-icon {
    margin-right: 1.2rem;
    font-size: 1.5rem;
    transition: var(--transition);
    cursor: pointer;
    color: #fff;
}

.footer .container .socials .socials-icon:hover {
    color: var(--color-bg);
    color: #fff;
}

.extra-black {
    color: #fff;
}



@media screen and (max-width:1024px) {
    .footer .container {
        margin-top: 3rem;
    }

    .footer .container .title img {
        width: 80%;
    }
}

@media screen and (max-width:768px) {
    .footer .container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

    }

    .footer .container .title {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .footer .container .title img {
        padding-left: 6rem;
        margin-bottom: 0;

    }



    .footer .container .title h2 {
        text-align: center;
        font-size: 3rem;
    }

    .footer .container .permalinks,
    .footer .container .other-links,
    .footer .container.contact--perma {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        font-size: 1.5rem;

    }
}

@media screen and (max-width:425px) {

    .footer .container {
        width: 100%;
    }

    .footer .container .permalinks,
    .footer .container .other-links,
    .footer .container.contact--perma {

        font-size: 1.2rem;

    }

    .footer .container .title img {
        padding-left: 2rem;
        margin-bottom: 0;

    }


}

@media screen and (max-width:375px) {
    .footer .container {
        width: 80%;
    }

    .footer .container .title h3 {
        font-size: 1.2rem;

    }


    .footer .container .permalinks,
    .footer .container .other-links,
    .footer .container.contact--perma {

        font-size: 1.3rem;

    }
}

@media screen and (max-width:320px) {
    .footer .container {
        width: 70%;
    }



    .footer .container .permalinks,
    .footer .container .other-links,
    .footer .container.contact--perma {

        font-size: 1.2rem;

    }
}


@media screen and (min-width:1800px) {
    .footer {
        width: 100%;
        background-color: #000;

    }

}