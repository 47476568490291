.landingPage {
    width: 100%;
    /* background-color: red; */
}

.landingPage .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

}

.onHover {
    background-color: #fff;
    border-radius: 1.2rem;
    padding: 0.5rem;
}


.mediaBg {
    background: url('../../assests/img-landingPage/Hoverbg/mediaVutuk.jpg');
}

.engineeringBg {
    background: url('../../assests/img-landingPage/Hoverbg/engineeringVutuk.jpg');
}

.landingPage .container .top {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;

}

.landingPage .container .top .logo img {
    width: 80%;
    margin: -8rem auto -8rem;

}

.landingPage .container .top .details h2 {
    margin-top: -5rem;
    width: 100%;
    font-size: 1.8rem;
    padding: 0.5rem;
    text-align: center;
    color: black;
    font-family: 'Lato', sans-serif;
    letter-spacing: 10px;
    /* text-align: center; */
    padding-left: auto;
    padding-right: auto;
    /* background-color: red; */

}

.landingPage .container .top .details .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 17rem;
}

.landingPage .container .top .details .buttons .btn {
    width: 250px;
    height: 80px;
    padding: 1.5rem 2rem;
    cursor: pointer;
    outline: 0;
    border: 1px solid var(--color-white);
    border-radius: 1.0rem;
    color: var(--color-white);
    background: var(--color-bg1);
    transition: var(--transition);
    font-size: 1.2rem;
}

.landingPage .container .top .details .buttons .btn:hover {
    background: transparent;
    border-color: var(-color-white);
}

.landingPage .container .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    /* background-color: #fff; */

}

.clients--logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background-color: #fff; */
    user-select: none;

}

.clients--logo-container .clients--logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 1rem;
    padding: 2rem 1rem;
    border-radius: 1.2rem;
    transition: var(--transition);
    border: 1px solid transparent;
}

.clients--logo-container .clients--logo:hover {
    background-color: transparent;
    border-color: var(--color-bg1);
}

@media screen and (min-width:1440px) {
    .landingPage .container .top {
        max-width: 1440px;
        margin: auto;
    }


}

@media screen and (max-width:834px) {
    .landingPage .container .top .details h2 {
        margin-top: 2rem;

    }
}



@media screen and (max-width:768px) {
    .landingPage .container .top .details .buttons {
        gap: 8rem;
    }

    .landingPage .container .top .details h2 {
        margin-top: 2rem;
        font-size: 1.3rem;
    }
}

@media screen and (max-width:688px) {
    .landingPage .container .top .details h2 {
        margin-top: 2rem;
        font-size: 1.3rem;
    }
}

@media screen and (max-width:600px) {
    .landingPage .container .top .details h2 {
        margin-top: 2rem;
        font-size: 1.3rem;
    }

    .landingPage .container .top .details .buttons {
        gap: 8rem;
    }
}

@media screen and (max-width:599px) {
    .landingPage .container .top .details h2 {
        /* margin-top: -2rem; */
        font-size: 1.3rem;
    }

    .landingPage .container .top .details .buttons {
        flex-direction: column;
        gap: 1rem;
    }
}


@media screen and (max-width:425px) {
    .landingPage .bottom .clients--logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }



    .landingPage .container .top .logo img {

        margin: 0 auto 0;
    }



    .landingPage .container .top .details .buttons {
        flex-direction: column;
        gap: 2rem;
    }

    .landingPage .container .top .details .buttons .btn {
        padding: 1rem;
    }

    .clients--logo-container {
        width: 80%;

    }


    .clients--logo-container .clients--logo img {
        width: 100%;
    }
}

@media screen and (max-width:320px) {
    .landingPage .container .top .logo img {
        width: 100%;
    }
}