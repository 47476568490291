@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;1,100;1,300&family=Poppins:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;1,100;1,300&family=Montserrat:wght@400;700&family=Poppins:wght@200&display=swap');

:root {
  --color-primary: #6c63ff;
  --color-success: #00bf8e;
  --color-warning: #f7c94b;
  --color-danger: #f75842;
  --color-danger-variant: #rgba(247, 88, 66, 0.4);
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.7);
  --color-black: #000;
  --color-bg: #fff;
  --color-nav: #090e24;
  --color-bg1: #2e3267;
  --color-bg2: #424890;
  --color-logo: #424890;

  --container-width-lg: 80%;
  --container-width-md: 90%;
  --container-width-sm: 94%;

  --transition: all 400ms ease;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif;
  line-height: 1.7;
  color: var(--color-white);
  background: var(--color-bg);
}





section {
  max-width: 1800px;
  margin: auto;
  height: 100%;
  overflow-x: hidden;
}


section h2 {
  text-align: center;
  margin-bottom: 4rem;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2;
}

h1 {
  font-size: 2.4rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.6rem;
}

h4 {
  font-size: 1.3rem;
}

ul {
  list-style: none;
}

a {
  color: var(--color-black);
  text-decoration: none;
}

img {
  width: 80%;
  display: block;
  object-fit: cover;
}

.hidden {
  display: none;
}

.callBtn {
  border: 1px solid var(--color-white);
  background-color: var(--color-white);
  color: var(--color-bg1);
  transition: var(--transition);
  font-size: 1.5rem;
  font-weight: 700;
  padding: .5rem;
  position: relative;
  overflow: hidden;
  /* background: transparent; */
  transition: var(--transition);
  animation: changeColor 10s infinite linear;
}

@keyframes changeColor {
  50% {
    color: var(--color-bg2);
  }

  100% {
    color: var(--color-bg1);
  }
}





.callBtn:hover {
  background-color: var(--color-black);
  color: var(--color-white);

}

/* SCROLLBAR MODIFICATION */


/* ////////////////////////////////////// */
::-webkit-scrollbar {
  width: 8px;
}

/* TRACKER */

::-webkit-scrollbar-track {
  background-color: var(--color-bg);
  box-shadow: 5px 1px 5px rgba(0, 0, 0, 0.9);
}

/* Handler */
::-webkit-scrollbar-thumb {
  background-color: #c7c3c3;
  transition: var(--transition);
  border-radius: 15px;

}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-bg2);
  cursor: -webkit-grab;
}

._productInfo_t8si8_9 {
  display: none;
}


@media screen and (max-width:425px) {
  .callBtn {

    font-size: 1rem;
  }
}