.contactus {
    margin-top: 8rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8rem;
    position: relative;
    left: -8%;


}

.contact__aside {
    width: 40%;
    height: 100%;
    background-color: var(--color-primary);
    border-radius: 2rem;
    position: relative;
    left: 30%;
}

.aside__image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.aside__image img {
    width: 90%;
}

.aside__details h2 {
    font-size: 3rem;
    margin-bottom: 0.5rem;
}

.aside__details p {
    margin-bottom: 3rem;
    text-align: center;
    font-size: 1.2rem;
}

.contact__aside-socials {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    flex-direction: column;
}

.contact__aside-social {

    padding: 0.5rem;
}

.contact__aside-social .socials {
    color: #fff;
}

.contact__form {
    padding: 8rem;
    width: 100%;
    border-radius: 2rem;
    background-color: var(--color-bg1);
    display: grid;
    grid-template-columns: 40% 60%;

}



.form .form__name {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.form .form__details {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.form input,
.form textarea {
    width: 100%;
    margin: 0.2rem;
    background: var(--color-bg);
    color: var(--color-white);
    padding: 1rem 0.5rem;
    outline: 0;
    border: 0;
    font-size: 0.8rem;
}

.form textarea {
    resize: none;
}

.form input,
.form textarea ::placeholder {
    font-size: 0.7rem;
}



.form .btn {
    color: var(--color-white);
    background: var(--color-danger);
    padding: 1rem;
    border: 1px solid transparent;
    margin-top: .2rem;

    cursor: pointer;
    transition: var(--transition);
}

.form .btn:hover {
    border-color: var(--color-white);
    background: transparent;
}

.contact__aside-socials .socials-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.2rem;
    font-size: 1.5rem;
    margin-left: 0.5rem;
    cursor: pointer;
    transition: var(--transition);
    color: #fff;
    margin-bottom: 0.5rem;
}

.contact__aside-socials .socials-icon:hover {
    transform: translateY(-10px);
}


.contact__aside-social {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.socials-icon-gmail {
    margin-top: -3rem;
    font-size: 4rem;
    color: #fff;
    transition: var(--transition);
}

.socials-icon-gmail:hover {

    transform: translateY(-10px);
}



@media screen and (max-width:1024px) {
    .contact__aside {
        width: 50%;
    }
}

@media screen and (max-width:768px) {
    .empty {
        display: none;
    }

    .contactus {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: var(--color-bg1);
        margin: 8rem auto;
    }

    .contact__aside {
        margin-top: 2rem;
        margin-bottom: 2rem;
        margin-right: 20rem;
    }

    .contact__form {
        width: 70%;
        border-radius: 0rem;
        background-color: var(--color-bg1);
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width:425px) {


    .contactus {
        flex-direction: column;
    }

    .contact__aside {
        width: 80%;
        margin-bottom: 2rem;
        margin-right: 12rem;
    }


}

@media screen and (max-width:375px) {


    .contact__aside {
        width: 80%;
        margin-bottom: 2rem;
        margin-right: 10rem;
    }


}

@media screen and (min-width:2560px) {
    .contactus {
        margin-top: 15rem;
        position: relative;
        /* top: 80%; */
        left: -8%;

    }

    .contact__aside-socials .socials-icon {
        margin-bottom: 5rem;
        font-size: 2rem;

    }

}